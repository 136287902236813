<template>
  <div>
    <v-card class="mt-10">
      <v-card-title>
        <v-row>
          <v-col cols="12" md="6">
            <div class="h3__list">
              <h3>{{ title }}</h3>
            </div>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="dataForTable"
        :search="search"
        :items-per-page="5"
      >
        <template v-slot:[`item.activo`]="slotProps">
          <v-chip
 
            :color="slotProps.item.activo == 1 ? 'green' : 'red' "
            outlined
          >
          {{ slotProps.item.activo === 1 ? 'Habilitado' : 'Suspendido' }}
          </v-chip>
          
        </template>
        <template v-slot:[`item.acciones`]="slotProps">
          <v-btn
            color="green"
            elevation="2"
            class="ml-2"
            icon
            outlined
            text
            @click="goToEditMode(slotProps.item)"
            ><v-icon>mdi-pencil</v-icon></v-btn
          >
          <v-btn
            color="red"
            class="ml-2"
            elevation="2"
            icon
            outlined
            text
            @click="openDelete(slotProps.item)"
            ><v-icon>mdi-delete</v-icon></v-btn
          >
        </template>
      </v-data-table>
    </v-card>
     
    <CardDelete :open="open" :options="options" @close="(value)=>(open = value)"/>

  </div>
</template>

<script>
import CardDelete from '../Cards/CardDelete.vue'
export default {
  name: "DatatableComponent",
  
  components:{
    CardDelete
  },
  props: {
    title: {
      type: String,
      default: " ",
    },
    
    headers: {
      type: Array,
      
    },
    dataForTable: {
      type: Array,
     
    },
    urlApi:{
      type:String,
      default:""
    },
    urlFront:{
      type:String,
      default:""
    },
    id:{
      type:String,
      default:""
    }
  },
  data() {
    return {
      search: "",
      open:false,
      options:{}
    };
  },

  methods: {
    goToEditMode(data) {
      
      this.$router.push(`/${this.urlFront}/${data[this.id]}`)

    },
    openDelete(data){
      this.open = true
      this.options =  {
        url:this.urlApi,
        data:data[this.id]
      }
    },
    
  },
};
</script>

<style lang="scss" scoped></style>
