<template>
  <div>
    <v-dialog v-model="openDialog" max-width="500">
      <v-card>
        <div v-if="loder" class="text-center pa-5">
          <v-progress-circular
            :size="50"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </div>
        <div v-else>
          <v-card-title> Ingrese el D.N.I del Efectivo </v-card-title>

          <v-card-text>
            <v-text-field
              v-model="dni"
              type="Number"
              :counter="8"
              label="D.N.I"
              required
              :autofocus="handleFocus"
              @keyup.enter="handlePolice"
            ></v-text-field>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              color="green darken-1"
              text
              @click="$emit('closeDialogDNI',false)"
            >
              Cancelar
            </v-btn>

            <v-btn
              color="green darken-1"
              text
              @click="handlePolice()"
              @keyup.enter="handlePolice"
            >
              Agregar
            </v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
  name: "DialogDNI",
  props:{
    openDialog:{
        type:Boolean,
        default:false
    },
    handleFocus:{
        type:Boolean,
        default:false
    }

  },
  data() {
    return {
        dni: "",
        loder: false,
    };
  },
  methods: {
    handlePolice() {
      this.loder = true;
      const payload = {
        dni: this.dni,
      };

      this.$store
        .dispatch("elecciones/getPolice", payload)
        .then(() => {
          this.formReset();
        })
        .catch((error) => {
          console.log(">>>>", error);
          this.formReset();
        });
    },
    formReset() {
    this.$emit('closeDialogDNI',false)
    this.$emit('closeFocus',false)
      this.loder = false;
      this.dni = "";
    },
  },
};
</script>

<style lang="scss" scoped></style>
