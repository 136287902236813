<template>
  <div>
    <v-card>
      <v-date-picker
        class="mt-10"
        v-model="dates"
        locale="ARG"
        full-width
        range
      ></v-date-picker>
      <v-divider></v-divider>
      <v-btn color="success" class="ma-2 rounded-lg" @click="() => {}">
        <v-icon left> mdi-eye-outline </v-icon>
        ver listado
      </v-btn>
      <v-btn color="success" class="ma-2 rounded-lg" @click="downloadExcel()">
        <v-icon left> mdi-file-download-outline </v-icon>
        Descargar Excel
      </v-btn>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "DownloadExcel",
  data() {
    return {
      dates: [],
      dateExcel: [],
    };
  },
  props: {
    urlApiExcel: {
      type: String,
      default: "",
    },
  },
  methods: {
    downloadExcel() {
      const url = process.env.VUE_APP_BACKEND_ENVIRONMENT + this.urlApiExcel;
      const params = this.dateExcel;
      axios
        .get(url, {
          params,
          responseType: "blob", // Indica que esperamos una respuesta de tipo Blob
        })
        .then((response) => {
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          // Crea un enlace temporal y simula un clic para descargar el archivo
          const link = document.createElement("a");
          link.href = fileURL;
          link.download = "archivo.xlsx"; // Nombre del archivo
          link.click();
        })
        .catch((error) => {
          console.log("Error al obtener el archivo:", error);
        });
    },
    validDataExcel([startDate, endDate]) {
      const date1 = new Date(startDate);
      const date2 = endDate ? new Date(endDate) : undefined;
      if (date2 === undefined ||  date1 < date2 || date1.getTime() === date2.getTime()){
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    dates(v) {
      const [startDate, endDate] = v;
      if(this.validDataExcel(v)){
        this.dateExcel = { desde: startDate, hasta: endDate  };
      }else{
        this.dateExcel = { desde: endDate, hasta: startDate  };
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
