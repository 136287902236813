import Vue from 'vue'
import Vuex from 'vuex'
import d6 from './modules/d6'
import user from './modules/user'
import elecciones from './modules/elecciones'


Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    d6,
    user,
    elecciones
  },
  
  
})
