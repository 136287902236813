<template>
  <div>
    <TitleDashboard :breadcrumbsItems="items" :title="'Novedades'" />

    <v-row class="mt-4">
      <v-col cols="12" md="8">
        <v-card elevation="0" min-height="200px">
          <v-card-title> Nueva novedad </v-card-title>
          <v-card-subtitle>
            Para dar conocimiento de novedades, escriba en el cuadro de texto y
            luego precione enviar "PARA NOVEDADES DE EXTREMA IMPORTANCIA ULTICE
            HT"
          </v-card-subtitle>

          <v-card-text>
            <v-textarea
              outlined
              label="Nueva Novedad"
              v-model="item_novedad"
            ></v-textarea>
          </v-card-text>
          <v-card-actions class="d-flex justify-center">
            <v-btn
              class="ma-2"
              :width="this.$vuetify.breakpoint.mobile ? '70%' : '30%'"
              :loading="loderButton"
              :disabled="!item_novedad"
              color="success"
              @click="sendData()"
            >
              enviar
              <v-icon right dark> mdi-email-arrow-right </v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <CardAsistencia />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TitleDashboard from "@/components/dashboard/TitleDashboard.vue";
import CardAsistencia from "@/components/Cards/CardAsistencia.vue";
import Vue from "vue";

export default {
  name: "Inicio",
  components: {
    TitleDashboard,
    CardAsistencia,
  },
  async created() {},
  data() {
    return {
      items: [
        {
          text: "Dashboard",
          disabled: false,
          link: false,
        },
        {
          text: "Novedades",
          disabled: false,
          link: false,
        },
      ],
      item_novedad: "",
      loderButton: false,
    };
  },

  methods: {
    async sendData() {
      this.loderButton = true;

      const id_escuela = localStorage.getItem("escuela");
      const userData = JSON.parse(localStorage.getItem("userData"));
      const id_policia = userData?.id_policia;
      const novedad = this.item_novedad;

      console.log(id_escuela, userData, id_policia, novedad);

      if (!id_escuela || id_escuela === "null" || !id_policia || !novedad) {
        Vue.prototype.$toast.open({
          message:
            "Para enviar una novedad, primero es necesario darse entrada al servicio ",
          position: "top-right",
          type: "error",
          duration: 3000,
        });

        this.loderButton = false;
        return;
      }

      try {
        const params = {
          url: "/escuelas/novedad/policia",
          formData: {
            id_escuela,
            id_policia,
            novedad,
          },
        };

        await Vue.prototype.$rest.post(params);
      } catch (err) {
        console.log(err);
      } finally {
        this.loderButton = false;
        this.item_novedad = "";
      }
    },
  },
};
</script>

<style></style>
