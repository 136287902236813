<template>
  <div>
    <v-dialog v-model="open" max-width="390" persistent>
      <v-card>
        <div v-if="spinner" class="spinner">
          <v-progress-circular
            indeterminate
            color="green"
            :size="spinnerSize"
          ></v-progress-circular>
        </div>

        <div v-else>
          <v-container>
            <v-card-text class="text-center container__icon">
              <v-icon
              :size="iconSize"
                color="red"
              >
                mdi-delete-circle-outline
              </v-icon>
            </v-card-text>
          </v-container>

          <v-card-title
          :class="titleClass"
            class="justify-center"
          >
            ¿Desea eliminar el registro???
          </v-card-title>

          <v-card-text class="justify-center text-center">
            Al eliminar el registro solo podra ser restautado por el
            Administrador
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              color="black"
              text
              @click="
                () => {
                  this.$emit('close', false);
                }
              "
            >
              Salir
            </v-btn>

            <v-btn color="red" text @click="deleteRegister()"> Eliminar </v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  name: "CardDelete",
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: ()=>({}),
    },
   
  },
  data() {
    return {
      spinner: false,
      spinnerSize: this.$vuetify.breakpoint.smAndUp ? 80 : 40,
      iconSize: this.$vuetify.breakpoint.smAndUp ? 110 : 80,
      titleClass: this.$vuetify.breakpoint.smAndUp ? "text-h5" : "text-h6",
    };
  },
  methods:{
    async deleteRegister(){
      try{
        this.spinner = true
        const response = await Vue.prototype.$rest.delete(this.options);
        await new Promise((resolve) => setTimeout(resolve, 3000)); // Esperar 3 segundos
        this.spinner = false;
        this.$emit('close', false);
        this.$router.go(0)
      }catch(e){
          console.log(e);
      }
    }

  }
  
};
</script>

<style lang="scss" scoped>
.spinner {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
