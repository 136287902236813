<template>
  <div>
    <TitleDashboard
      :breadcrumbsItems="items"
      :title="'Finalización del Servicio'"
    />

    <v-row class="mt-4">
      <v-col cols="12" md="8">
        <v-card elevation="0" min-height="200px">
          <v-card-title> Salidas del personal a su cargo </v-card-title>
          <v-card-subtitle>
            Para dar retiro al efectivo precione el boton "salida" correspondiente a cada uno. Una vez que hayas concluido el servicio, pulsa el botón "Finalizar"  
          </v-card-subtitle>
         
          <v-card-text v-for="(item, i) in presentesList" :key="i" class="pb-2">
            <div class="d-flex justify-space-between align-center" width="100%">
              <p class="ma-0">
                {{ item.jerarquia + " " + item.apellido + " " + item.nombre }}
              </p>
              <v-btn
                color="success"
                class="ml-2"
                elevation="2"
                small
               
                @click="deleteItemList(item.id)"
                >Salida</v-btn
              >
            </div>
            <v-divider class="mt-2"></v-divider>
          </v-card-text>
          <v-card-actions class="d-flex justify-center">
            <v-btn
              class="ma-2"
              :width="this.$vuetify.breakpoint.mobile ? '70%' : '30%'"
              dark
              color="primary"
              @click.stop="dialog = true"
            >
             finalizar
            </v-btn>
          </v-card-actions>
         
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <CardAsistencia />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TitleDashboard from "@/components/dashboard/TitleDashboard.vue";
import CardAsistencia from "@/components/Cards/CardAsistencia.vue";
import { mapState } from "vuex";

export default {
  name: "Inicio",
  components: {
    TitleDashboard,
    CardAsistencia,
  },
  async created() {
    await this.$store.dispatch("elecciones/getEscuelas");
    console.log(">>>>", this.escuelas);
  },
  data() {
    return {
      items: [
        {
          text: "Dashboard",
          disabled: false,
          link: false,
        },
        {
          text: "Salidas ",
          disabled: false,
          link: false,
        },
      ],
      presentesList: [
        {
          id: 1,
          jerarquia: "Of Sub Inspector",
          apellido: "Perez",
          nombre: "Dario Oscar",
        },
        {
          id: 2,
          jerarquia: "Cabo 1°",
          apellido: "Esquivel",
          nombre: "Leonardo Marino",
        },
        { id: 3, jerarquia: "Agte", apellido: "Ovalles", nombre: "Michael" },
        { id: 4, jerarquia: "Agte", apellido: "Riveros", nombre: "Eric" },
      ],
    };
  },

  mounted() {
    this.datosUser = JSON.parse(localStorage.getItem("token"));
  },

  methods: {
    deleteItemList(id) {
      this.presentesList = this.presentesList.filter((elem) => elem.id != id);
    },
  },
  computed: mapState({
    escuelas: (state) => state.elecciones.escuelas,
  }),
};
</script>

<style></style>
