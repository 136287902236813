<template>
  <div>
    <TitleDashboard
      :breadcrumbsItems="items"
      :title="'Ingresos al establecimiento'"
    />

    <v-row class="mt-4">
      <v-col cols="12" md="8">
        <v-card elevation="0" min-height="200px" v-if="stateComplete">
          <v-card-title> Nueva Entrada </v-card-title>
          <v-card-subtitle>
            Ud ya completo el ingreso al establecimiento, en esta seccion solo
            podra dar presente a nuevos efectivos
          </v-card-subtitle>
          <v-card-actions class="d-flex justify-center">
            <v-btn
              class="mx-2"
              :width="this.$vuetify.breakpoint.mobile ? '70%' : '30%'"
              dark
              color="success"
              @click.stop="(dialog = true), (handleFocus = true)"
            >
              <v-icon dark> mdi-plus </v-icon>
            </v-btn>
          </v-card-actions>
          <v-card-text v-for="(item, i) in policeList" :key="i" class="pb-2">
            <div class="d-flex justify-space-between align-center" width="100%">
              <p class="ma-0">
                {{ item.value.jerarquia + " " + item.value.nombre }}
                <br />
                {{ item.value.destino }}
              </p>

              <v-btn
                color="red"
                class="ml-2"
                elevation="2"
                icon
                outlined
                text
                @click="deleteItemList(item.value.id_policia)"
                ><v-icon>mdi-delete</v-icon></v-btn
              >
            </div>
            <v-divider class="mt-2"></v-divider>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primary"
              @click="sendPoliceList()"
              :disabled="isContinueDisabled"
              :loading="loderButton"
            >
              enviar
              <v-icon right dark>mdi-send</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-stepper v-model="e1" v-else>
          <v-stepper-header>
            <v-stepper-step :complete="e1 > 1" step="1">
              Seleccionar Escuela
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="e1 > 2" step="2">
              Entrada a Personal Policial
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step step="3"> Estado de Escuela </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-card class="mb-12" elevation="0" min-height="200px">
                <v-card-title> Seleccione la escuela </v-card-title>
                <v-card-subtitle>
                  Busque en el menu desplegable su escuela y luego pulse el
                  boton continuar
                </v-card-subtitle>

                <v-autocomplete
                  v-model="escuela"
                  :items="escuelas"
                  item-text="dataText"
                  item-value="dataValue"
                  label="Seleccionar Escuela"
                  outlined
                />
              </v-card>

              <v-btn color="primary" :disabled="!escuela" @click="e1 = 2">
                Continuar
              </v-btn>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-card elevation="0" min-height="200px">
                <v-card-title> Entradas al Establecimiento </v-card-title>
                <v-card-subtitle>
                  Precione el boton + para agregar los Efectivos Policiales de
                  turno, "Ud como encargado del servicio ya tiene presente de
                  forma automatica"
                </v-card-subtitle>
                <v-card-actions class="d-flex justify-center">
                  <v-btn
                    class="mx-2"
                    :width="this.$vuetify.breakpoint.mobile ? '70%' : '30%'"
                    dark
                    color="success"
                    @click.stop="(dialog = true), (handleFocus = true)"
                  >
                    <v-icon dark> mdi-plus </v-icon>
                  </v-btn>
                </v-card-actions>
                <v-card-text
                  v-for="(item, i) in policeList"
                  :key="i"
                  class="pb-2"
                >
                  <div
                    class="d-flex justify-space-between align-center"
                    width="100%"
                  >
                    <p class="ma-0">
                      {{ item.value.jerarquia + " " + item.value.nombre }}
                      <br />
                      {{ item.value.destino }}
                    </p>

                    <v-btn
                      color="red"
                      class="ml-2"
                      elevation="2"
                      icon
                      outlined
                      text
                      @click="deleteItemList(item.value.id_policia)"
                      ><v-icon>mdi-delete</v-icon></v-btn
                    >
                  </div>
                  <v-divider class="mt-2"></v-divider>
                </v-card-text>
              </v-card>

              <v-btn text outlined class="mx-2" @click="e1 = 1"> Atras </v-btn>
              <v-btn
                color="primary"
                @click="sendPoliceList()"
                :disabled="isContinueDisabled"
                :loading="loderButton"
              >
                Continuar
              </v-btn>
            </v-stepper-content>

            <v-stepper-content step="3">
              <v-card elevation="0" min-height="200px">
                <v-card-title> Estado de Escuela </v-card-title>
                <v-card-subtitle>
                  Para finalizar por favor complete la informacion del
                  Establecimiento
                </v-card-subtitle>

                <v-switch
                  v-model="dataform.StateSchool"
                  :label="
                    dataform.StateSchool
                      ? 'Escuela Habilitada'
                      : 'Escuela No Habilitada'
                  "
                ></v-switch>

                <v-select
                  :items="itemUrnas"
                  v-model="dataform.urnas"
                  label="Seleccione cantidad de urnas"
                  required
                  outlined
                ></v-select>
              </v-card>

              <v-btn text outlined class="mx-2" @click="e1 = 2"> Atras </v-btn>
              <v-btn
                :loading="loderSendButton"
                color="primary"
                @click="sendSchool()"
              >
                enviar
              </v-btn>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-col>
      <v-col cols="12" md="4">
        <CardAsistencia />
      </v-col>
    </v-row>
    <DialogDNI
      :openDialog="dialog"
      :handleFocus="handleFocus"
      @closeDialogDNI="(i) => (dialog = i)"
      @closeFocus="(i) => (handleFocus = i)"
    />
  </div>
</template>

<script>
import TitleDashboard from "@/components/dashboard/TitleDashboard.vue";
import CardAsistencia from "@/components/Cards/CardAsistencia.vue";
import DialogDNI from "@/components/Cards/DialogDNI.vue";
import { mapState } from "vuex";
import Vue from "vue";

export default {
  name: "Inicio",
  components: {
    TitleDashboard,
    CardAsistencia,
    DialogDNI,
  },

  data() {
    return {
      stateComplete: false,
      handleFocus: false,
      dialog: false,
      escuela: null,
      loderButton: false,
      loderSendButton: false,
      e1: 1,
      itemUrnas: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
      dataform: {
        StateSchool: false,
        urnas: null,
      },

      items: [
        {
          text: "Dashboard",
          disabled: false,
          link: false,
        },
        {
          text: "Ingresos ",
          disabled: false,
          link: false,
        },
      ],
    };
  },
  async created() {
    this.validCompleteForm();
    await this.$store.dispatch("elecciones/getEscuelas");

  },
  methods: {
    async validCompleteForm() {
      if (localStorage.getItem("finallyForm")) {
        this.stateComplete = true;
      } else {
        const payload = {
          dni: this.userData.dni,
        };
        await this.$store.dispatch("elecciones/getPolice", payload);
      }
    },
    async sendPoliceList() {
      this.loderButton = true;
      //contruye la lista a enviar
      const sendPoliceList = this.policeList.map((police) => ({
        id_policia: police.value.id_policia,
      }));
      const sendData = {
        asistencias: sendPoliceList,
        id_escuela: this.escuela,
      };

      try {
        const params = {
          url: "/indice/asistencia",
          formData: sendData,
        };

        await Vue.prototype.$rest.post(params);

        this.e1 = 3;
      } catch (err) {
        console.log(err);
        this.loderButton = false;
      } finally {
        this.$store.dispatch("elecciones/getPolice", null);
        this.loderButton = false;
      }
    },
    async sendSchool() {
      this.loderSendButton = true;
      const sendData = {
        id_escuela: localStorage.getItem("escuela"),
        id_policia: this.userData.id_policia,
        habilitada: this.dataform.StateSchool,
        urnas: this.dataform.urnas,
      };

      try {
        const params = {
          url: "/escuelas/novedad",
          formData: sendData,
        };

        await Vue.prototype.$rest.post(params);
        this.stateComplete = true;
      } catch (err) {
        console.log(err);
      } finally {
        this.loderSendButton = false;
        this.stateComplete = true;
        localStorage.setItem("finallyForm", JSON.stringify(true));
      }
    },
    deleteItemList(id) {
      this.$store.dispatch("elecciones/deletePolice", id);
    },
  },
  computed: {
    ...mapState("elecciones", ["escuelas", "policeList"]),
    ...mapState("user", ["userData"]),
    isContinueDisabled() {
      return this.policeList.length === 0;
    },
  },
  watch: {
    escuela(v) {
      localStorage.setItem("escuela", JSON.stringify(v));
    },
  },
};
</script>

<style></style>
